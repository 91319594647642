.movie-card {
    margin: 0 0;
}

.slider-style {
    margin: 1rem 0;
}

.carousel-container {
    padding: 1rem;
}

.movie-carousel-container {
    margin: 2rem;
}

.tag-name-heading {
    font-weight: 500;
}

.carousel-heading-wrapper {
    display: flex;
    justify-content: space-between;
}

.view-all-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
  }