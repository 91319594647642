.main-content {
  min-height: calc(80.3vh - 70px);
}

.main-swiper-container {
  position: relative;
}
/* .logo-dhaakad-cinema {
  margin-left: px;
} */

/* navbar css start */
.navbar-wrapper {
  position: relative;
}
.navbar-wrapper .top-navbar {
  z-index: 11;
  position: relative;
  top: 0px;
  background: linear-gradient(to right, #5A0223, #1E2744);}
  
.top-navbar {
  height: 60px;
  width: 100%;
}
.top-navbar .navbar-logo {
  width: 160px;
  height: 100px;
}
img.navbar-logo-img {
  height: 48px;
  position: relative;
  margin-right: 15px;
}
img.Dhakad {
  object-fit: cover;
  width: 80px;
  height: 10px;
}
img.Cinema {
  object-fit: cover;
  margin-left: 4px;
  margin-right: 10px;
  width: 200px;
  height: 40px;
}
.top-navbar .navbar-nav li.nav-item.active-link {
  font-size: 16px;
  font-weight: 400;
}
.active_div {
  color: #ffcc00 !important;
  border-bottom: 2px solid #ffcc00;
}
.top-navbar .navbar-nav li.nav-item.active-link {
  padding: 5px 25px;
}
.top-navbar .navbar-nav li.nav-item.active-link a {
  color: #ffff;
  padding: 5px 0px;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease; 
}
/* .top-navbar .navbar-nav a:hover {
  transform: scale(1);
  animation-name: zoomInNav;
  animation-duration: 0.3s;
  animation-fill-mode:forwards;
} */
.material-icons svg.MuiSvgIcon-root {
  margin: 4px 16px;
  cursor: pointer;
  color: #fff;
}
.dropdown-content li {
  padding: 0 !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown .dropdown-content a {
  /* border-bottom: unset !important; */
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-top: 15px;
}

.dropdown-content a {
  color: #323232 !important;
  background-color: #fff !important;
  padding: 8px 16px !important;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
}
.dropdown .dropdown-content a:hover {
  background: #ffcc00 !important;
  color: #fff !important;
}
.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}
/* navbar css end */

@media screen and (max-width: 320px) {
  .top-navbar {
    width: 100%;
  }
  .top-navbar .navbar-logo {
    width: 100%;
    height: 40px;
  }
  .search-container input.input-open {
    /* width: 100% !important; */
    display: none;
  }
  .search-container {
    margin: 0;
  }
  .top-navbar.navbar > .container {
    flex-wrap: unset;
  }
  .top-navbar .material-icons svg.MuiSvgIcon-root {
    margin: 4px 8px;
  }
}

@media screen and (min-device-width: 245px) and (max-device-width: 267px) {
  img.Cinema {
    object-fit: cover;
    margin-left: 4px;
    margin-right: 2px;
    width: 78px;
    height: 15px;
    margin-right: 20px;
  } 
}


@media screen and (min-device-width: 268px) and (max-device-width: 320px) {
  img.Cinema {
    object-fit: cover;
    margin-left: 4px;
    margin-right: 2px;
    width: 100px;
    height: 20px;
    margin-right: 20px;
  } 
}

@media screen and (min-device-width: 360px) and (max-device-width: 620px) {
  .search-container input.input-open {
    width: 50% !important;
    display: flex !important;
    margin-left: auto;
    font-size: 12px !important;
    height: 20px;
    margin-top: 6px;
  }
  .search-container .searchIcon {
    height: 15px !important;
    width: 15px !important;
    top: 8px !important;
  }
}

@media screen and (min-device-width: 321px) and (max-device-width: 620px) {
  .top-navbar {
    width: 100%;
    height: 54px;
  }
  .top-navbar .navbar-logo {
    width: 100%;
    height: 40px;
  }
  /* .search-container input.input-open {
    display: none;
  } */
  .search-container {
    margin: 0;
  }
  .top-navbar.navbar > .container {
    flex-wrap: unset;
  }
  .top-navbar .material-icons svg.MuiSvgIcon-root {
    margin: 4px 8px;
  }
  img.navbar-logo-img {
    height: 30px;
    position: relative;
    margin-right: 0px;
  }

  img.Cinema {
    object-fit: cover;
    margin-left: 2px;
    margin-right: 2px;
    width: 120px;
    height: 25px;
  }
}
@media screen and (min-device-width: 421px) and (max-device-width: 480px) {
  .top-navbar {
    width: 100%;
  }
  .top-navbar .navbar-logo {
    width: 100%;
    height: 40px;
  }
  /* .search-container input.input-open {
    width: 100% !important;
  }
  .search-container {
    margin: 0;
  } */
  .top-navbar.navbar > .container {
    flex-wrap: unset;
  }
  .top-navbar .material-icons svg.MuiSvgIcon-root {
    margin: 4px 8px;
  }
}
@media screen and (min-device-width: 481px) and (max-device-width: 540px) {
  .top-navbar {
    width: 100%;
    height: 54px;
  }
  .top-navbar .navbar-logo {
    width: 100%;
    height: 40px;
  }
  .search-container input.input-open {
    width: 100% !important;
  }
  .search-container {
    margin: 0;
  }
  .top-navbar.navbar > .container {
    flex-wrap: unset;
  }
  .top-navbar .material-icons svg.MuiSvgIcon-root {
    margin: 4px 8px;
  }
}
@media screen and (min-device-width: 541px) and (max-device-width: 640px) {
  .top-navbar .container {
    max-width: 100%;
  }
  .search-container input.input-open {
    width: 100% !important;
  }
  ul.navbar-nav.mx-auto.my-2.my-lg-0 {
    flex-direction: inherit;
    display: none;
  }
  .top-navbar .navbar-nav li {
    padding: 5px 9px;
  }
  .top-navbar .navbar-logo {
    width: 104px;
    height: 88px;
    object-fit: cover;
  }
}
@media screen and (min-device-width: 641px) and (max-device-width: 767px) {
  .top-navbar .container {
    max-width: 100%;
  }
  .search-container input.input-open {
    width: 100% !important;
  }
  ul.navbar-nav.mx-auto.my-2.my-lg-0 {
    flex-direction: inherit;
    display: none;
  }
  .top-navbar .navbar-nav li {
    padding: 5px 9px;
  }
  .top-navbar .navbar-logo {
    width: 104px;
    height: 88px;
    object-fit: cover;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .top-navbar .navbar-logo {
    width: 104px;
    height: 88px;
  }
  li.nav-item.active-link {
    display: none;
  }
  .search-container input.input-open {
    width: 100% !important;
  }
  .top-navbar .container {
    max-width: 100%;
  }
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1199px) {
  .top-navbar .navbar-nav li.nav-item.active-link {
    padding: 5px 20px;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .top-navbar .navbar-logo {
    width: 104px;
    height: 88px;
    object-fit: cover;
  }
  li.nav-item.active-link {
    display: none;
  }
}

@media screen and (min-device-width: 769px) and (max-device-width: 1025px) {
  .top-navbar .navbar-nav li {
    padding: 5px 9px;
  }
  .top-navbar .navbar-logo {
    width: 104px;
    height: 88px;
    object-fit: cover;
  }
}
.card-bucket {
  position: relative;
}
.card-bucket svg {
  font-size: 26px;
  color: #ffff;
}

.card-bucket span.card-items {
  height: 18px;
  text-align: center;
  width: 18px;
  font-size: 12px;
  display: flex;
  border-radius: 50%;
  background-color: #ffcc00;
  position: absolute;
  justify-content: center;
  font-weight: 600;
  color: #3968b6;
  top: -6px;
  right: -5px;
}


.search-icon{
  color: #fff;
  font-weight: 800;
  
}


