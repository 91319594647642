.rent-text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  /* gap: 8px; Adds spacing between elements */

  /* border: 1px solid rgba(255, 255, 255, 0.5); Semi-transparent border */

  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); Soft shadow */
  border-radius: 10px; /* Rounded corners */
  color: white; /* Text color */
  padding: 0.3rem 2rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  width: fit-content !important;
}

.movie-buttons {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 0.3rem 2rem;
}
.rent-btn {
  background-color: #ffcc00;
}

.more-info-btn {
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.movie-actions-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
