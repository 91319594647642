.commonMovieCard{
  position: relative;
  cursor: pointer;
  max-width: 270px;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) !important; 
}

.commonMovieCard:hover {
  transform: scale(1.02) !important;
}

.movieImageIconContainer{
  position: relative;

}
.movieImage{
  height: 150px;
  width: 306px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.overLayRentIcon{
  height: 14px;
  width: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  margin-left: 5px;
  margin-bottom: 5px;
}

.movieDetails{
  padding: 2% 3% !important;
  background-color: #ffcc00;
  width: 270px;
  padding: 2%;
  border-radius:0 0 5px 5px !important;
  position: absolute;
  z-index: 999999;
  min-height: 60px;
  max-height: 120px;
  overflow-y: auto;
  transition: opacity 0.3s ease !important;
  opacity: 0;
}

.commonMovieCard:hover .movieDetails {
  opacity: 1 !important;
}

.movieDetails div{
  margin-top: 1px;
}

.rentIcon{
  height: 14px;
  width: 14px;
  color: #0D6EFD;
}

.rentedDetails{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}

.movieTitleContainer{
  display: flex;
  flex-direction: row;
}

.movieTitle{
  font-size: 14px;
  font-weight: bolder;
  width: 75%;
}

.movieYearDetailContainer{
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: bold;
  color: #3968b6;
}

.movieDescription{
  font-size: 10px;
  font-weight: bold;
  min-height: 80px;
}
.movieMarginTop{
  margin-top: 2px;
}

.borderRadiusBottom{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: solid 1px #e6e6e6
}



.movieSubTitle{
  font-size: 12px;
}

.moveCardUp{
  margin-top: -25px;
}

.cardMinHeight{
  min-height: 230px;
}




@media screen and (min-device-width: 221px) and (max-device-width: 610px) {

.commonMovieCard{
  position: relative;
  cursor: pointer;
  width: 98%;
  margin: auto;
  margin-bottom: 28px;
  
  /* style={{display:"flex", paddingLeft:"10px", paddingRight:"8px"}} */
}

.movieImageIconContainer{
  position: relative;
  
}

.movieDetails{
  background-color: #ffcc00;
  width: 270px;
  padding: 2%;
  border-radius: 0 0 5px 5px;
  position: absolute;
  z-index: 999999;
}


.overLayRentIcon{
  height: 14px;
  width: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  margin-left: 5px;
  margin-bottom: 5px;
}

.movieDetails{
  background-color: #ffcc00;
  width: 270px;
  padding: 2%;
  border-radius: 0 0 5px 5px;
  position: absolute;
  z-index: 999999;
}

.movieDetails div{
  margin-top: 1px;
}

.rentIcon{
  height: 14px;
  width: 14px;
  color: #0D6EFD;
}

.rentedDetails{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}

.movieTitleContainer{
  display: flex;
  flex-direction: row;
}

.movieTitle{
  padding-top: 8px;
  font-size: 14px;
  font-weight:800;
  color: black;
  width: 100%;
}

.movieYearDetailContainer{
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: bold;
  color: #3968b6;
}

.movieDescription{
  font-size: 10px;
  font-weight: bold;
  min-height: 80px;
}
.movieMarginTop{
  margin-top: 2px;
}

.borderRadiusBottom{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}



.movieSubTitle{
  font-size: 12px;
}

.moveCardUp{
  margin-top: -25px;
}

.cardMinHeight{
  min-height: 230px;
}

}


@media screen and (min-device-width: 324px) and (max-device-width: 347px) {

  .movieImage{
      height: 120px;
     width: 95%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
     
  }

  .movieDetails{
      background-color: #ffcc00;
      width: 95%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 100px;
      overflow: hidden;
      margin-left: 2%;
      
  }
  
  }



@media screen and (min-device-width: 348px) and (max-device-width: 361px) {

  .movieImage{
      height: 120px;
     width: 95%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
     
  }


  .movieDetails{
      background-color: #ffcc00;
      width: 95%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 100px;
      overflow: hidden;
      margin-left: 2%;
     
  }
  
  }

@media screen and (min-device-width: 362px) and (max-device-width: 383px) {

  .movieImage{
      height: 120px;
     width: 95%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
     
  }

  .movieDetails{
      background-color: #ffcc00;
      width: 95%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 110px;
      overflow: hidden;
      margin-left: 2%;
  
  }
  
  }



@media screen and (min-device-width: 384px) and (max-device-width: 409px) {

  .movieImage{
      height: 120px;
     width: 95%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
     
  }

  .movieDetails{
      background-color: #ffcc00;
      width: 95%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 110px;
      overflow: hidden;
      margin-left: 2%;
      
  }
  
  }

@media screen and (min-device-width: 410px) and (max-device-width: 433px) {

  .movieImage{
      height: 120px;
     width: 95%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
     
  }

  .movieDetails{
      background-color: #ffcc00;
      width: 95%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 110px;
      overflow: hidden;
      margin-left: 2%;
     
  }
  
  }

@media screen and (min-device-width: 434px) and (max-device-width: 479px) {

  .movieImage{
      height: 120px;
     width: 96%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
     
  }

  .movieDetails{
      background-color: #ffcc00;
      width: 96%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 110px;
      overflow: hidden;
      margin-left: 2%;
  
  }
  
  }




@media screen and (min-device-width: 480px) and (max-device-width: 519px) {

  .movieImage{
      height: 120px;
     
      width: 96%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
     
  }

  .movieDetails{
      background-color: #ffcc00;
      width: 96%;
      margin-left: 2%;
      /* padding: 2%; */
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 110px;
      overflow: hidden;
     
     
  }
  
  }




@media screen and (min-device-width: 520px) and (max-device-width: 549px) {

  .movieImage{
      height: 120px;
     
      width: 97%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
     
  }

  .movieDetails{
      background-color: #ffcc00;
      width: 97%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 120px;
      overflow: hidden;
      margin-left: 1%;
     
  }
  
  }


  @media screen and (min-device-width: 550px) and (max-device-width: 610px) {

      .movieImage{
          height: 120px;
         
          width: 97%;
          margin: auto;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
         
      }

      .movieDetails{
  background-color: #ffcc00;
  width: 97%;
  padding: 2%;
  border-radius: 0 0 5px 5px;
  position: absolute;
  z-index: 999999;
  height: 130px;
  overflow: hidden;
  margin-left: 1%;
}

}


@media screen and (min-device-width: 611px) and (max-device-width: 670px) {

  .movieImage{
      height: 120px;
     
      width: 97%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      }

      .movieDetails{
          background-color: #ffcc00;
          width: 97%;
          /* padding: 2%; */
          border-radius: 0 0 5px 5px;
          position: absolute;
          z-index: 999999;
          height: 130px;
          overflow: hidden;
          margin-left: 1%;
      }

}



@media screen and (min-device-width: 671px) and (max-device-width: 1080px) {

  .movieImage{
      height: 120px;
     
      width: 97%;
      margin: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      }

      .movieDetails{
          background-color: #ffcc00;
          width: 97%;
          /* padding: 2%; */
          border-radius: 0 0 5px 5px;
          position: absolute;
          z-index: 999999;
          height: 130px;
          overflow: hidden;
          margin-left: 1%;
      }

      .movieTitle{
          width: 360px;
          font-size: 20px;
      }

}

/* @media screen and (min-device-width: 1081px) and (max-device-width: 1180px) {

  .movieDetails{
      background-color: #ffcc00;
      width: 310px;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 200px;
      overflow: hidden;
     
  }


  .commonMovieCard{
      position: relative;
      transition: margin 100ms;
      cursor: pointer;
      max-width: 270px;
  }
  
  .movieImageIconContainer{
      position: relative;
  
  }
  .movieImage{
      height: 150px;
      width: 310px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
  }




}
*/





@media screen and (min-device-width: 1081px) and (max-device-width: 1500px) {

  .movieDetails{
      background-color: #ffcc00;
      width: 370px;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 200px;
      overflow: hidden;
     
  }

  .movieSubTitle{
      font-size: 12px;
      width: 360px;
  }

  .movieTitle{
      width: 360px;
      font-size: 24px;
  }


  .commonMovieCard{
      position: relative;
      transition: margin 100ms;
      cursor: pointer;
      max-width: 270px;
  }
  
  .movieImageIconContainer{
      position: relative;
  
  }
  .movieImage{
      height: 160px;
      width: 370px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
  }


}


@media screen and (min-device-width: 1501px) and (max-device-width: 1900px) {

  .movieDetails{
      background-color: #ffcc00;
      width: 100%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 200px;
      overflow: hidden;
    
  }

  .commonMovieCard{
      position: relative;
      transition: margin 100ms;
      cursor: pointer;
      max-width: 370px;
  }
  
  .movieImageIconContainer{
      position: relative;
  
  }

  .movieSubTitle{
      font-size: 12px;
      width: 360px;
  }

  .movieTitle{
      width: 360px;
      font-size: 26px;
  }

  .movieImage{
      height: 160px;
      width: 370px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
  }
}

@media screen and (min-device-width: 1901px) and (max-device-width: 2500px) {

  .movieDetails{
      background-color: #ffcc00;
      width: 100%;
      padding: 2%;
      border-radius: 0 0 5px 5px;
      position: absolute;
      z-index: 999999;
      height: 200px;
      overflow: hidden;
     
  }

  .commonMovieCard{
      position: relative;
      transition: margin 100ms;
      cursor: pointer;
      max-width: 380px;
  }
  
  .movieImageIconContainer{
      position: relative;
  
  }

  .movieSubTitle{
      font-size: 12px;
      width: 370px;
  }

  .movieTitle{
      width: 370px;
      font-size: 26px;
  }

  .movieImage{
      height: 160px;
      width: 380px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
  }
}


    
      