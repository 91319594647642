.content-type-heading-container {
    /* font-size: 2rem; */
    color: #5C0223;
    margin-bottom: 20px;
}

.content-type-heading {
    color: #5C0223;
    margin: 0px;
    line-height: 5rem;
}

.section-heading-wrapper .content-type-heading {
    /* background-color: #ffcc00; */
    border-radius: 30px;
    color: #5C0223;
    font-weight: bolder;
    line-height: 2rem;
    text-align: left;
    width: 50%;
    margin: 20px 0 30px 1rem;
    text-transform: uppercase;
}

/* Responsive Styles */
@media screen and (min-device-width: 1920px) and (max-device-width: 2200px) {
    .section-heading-wrapper .content-type-heading {
        font-size: 26px;
        width: 15%;
    }
}

@media screen and (min-device-width: 1700px) and (max-device-width: 1919px) {
    .section-heading-wrapper .content-type-heading {
        font-size: 26px;
        width: 18%;
        padding-top: 14px;
        padding-bottom: 14px;
    }
}

@media screen and (min-device-width: 1441px) and (max-device-width: 1699px) {
    .section-heading-wrapper .content-type-heading {
        font-size: 26px;
        width: 25%;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@media screen and (min-device-width: 1220px) and (max-device-width: 1440px) {
    .section-heading-wrapper .content-type-heading {
        font-size: 26px;
        width: 17%;
    }
}

@media screen and (min-device-width: 220px) and (max-device-width: 259px) {
    .section-heading-wrapper .content-type-heading {
        font-size: 10px;
        width: 55% !important;
        margin-bottom: 20px;
        margin-top: 15px;
    }
}
