.movies-list-page {
  margin: 2rem;
}

.movies-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
}

/* Super Large Desktop (5 items per row) */
@media (min-width: 3000px) {
  .movies-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* Desktop (3 items per row) */
@media (min-width: 1024px) and (max-width: 2999px) {
  .movies-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Tablet (3 items per row) */
@media (min-width: 464px) and (max-width: 1023px) {
  .movies-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Mobile (2 items per row) */
@media (max-width: 463px) {
  .movies-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}



.movie-list-nav {
  color: #e0b60c;
  text-decoration: none;
}

.movies-list-page-heading {
    font-weight: bolder;
}
